import React, { Component } from "react";
var cdnUrl = "https://d2rvxcax2co8em.cloudfront.net";

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="surf-static-page term-use">
          <div className="header-wrap">
            <a className="logo-sec" href="/" alt="">
              <img src={cdnUrl + "/surf_logo.svg"} className="logoImage" />
            </a>
          </div>
          <div className="static-page-container">
            <p className="page-title">TERMS AND CONDITIONS</p>
            <div>
              <p className="sub-heading">LEGAL NOTICE</p>
              <p className="paragraph">
                Please read these terms carefully before using this site. This web site is provided by Modon Properties PJSC and its subsidiaries (“Modon”) and may only be used for informational
                purposes only. By viewing the site or downloading materials from the site, you agree to abide by the terms and conditions set forth in this notice. If you do not agree to abide by
                these terms and conditions, do not use the site or download materials from the site.
              </p>
            </div>
            <div>
              <p className="sub-heading">CONDITIONS OF SALE</p>
              <p className="paragraph">
                By purchasing your tickets online you are deemed to have accepted the Surf Abu Dhabi's terms and conditions of Admission. By completing the online ticket purchase, you acknowledge
                acceptance of Surf Abu Dhabi's terms of Admission. To use tickets, present the e-ticket (electronic or printed) and the credit card used for purchase or valid government-issued ID
                matching the booking name. All transactions are conducted in United Arab Emirates Dirhams through credit card payments. For data retention details, please consult our Privacy Policy.
                Purchased tickets will be emailed; contact us if not received. Tickets are non-exchangeable, non-refundable, and void if resold, transferred, altered, or damaged. Alteration, exchange,
                transfer, or resale, unless by Modon-approved licensed distributors, renders tickets void. Additional terms may apply for non-ticket purchases, and you can review them before
                confirming your booking.
              </p>
              <ul>
                <li className="paragraph">Refunds will be done only through the Original Mode of Payment.</li>
                <li className="paragraph">Any dispute or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of UAE.</li>
              </ul>
            </div>
            <div>
              <p className="sub-heading">ADMISSION</p>
              <ul>
                <li className="paragraph">All guests must have a valid ticket or reservation to enter or use any of Surf Abu Dhabi’s attractions.</li>
                <li className="paragraph">Multiple entries/exists are not allowed once entering Surf Abu Dhabi.</li>
                <li className="paragraph">
                  Prices, operating schedules and attractions availability are subject to change without prior notice. Surf Abu Dhabi does not issue rain checks or refunds due to inclement weather
                </li>
                <li className="paragraph">Children below the age of 12 must be accompanied by an adult over the age of 18.</li>
                <li className="paragraph">
                  All bags are subject for inspection. Bringing food, drinks, glassware, picnic baskets, hazardous or dangerous items and pets are not allowed inside Surf Abu Dhabi.
                </li>
                <li className="paragraph">
                  Bicycles, scooters, and personal flotation devices are not permitted in certain areas of Surf Abu Dhabi. Please adhere to instructions from Surf Abu Dhabi Staff and/or signage.
                </li>
                <li className="paragraph">The Management reserves the right to refuse admission for any guest and to control occupancy when required. </li>
              </ul>
            </div>
            <div>
              <p className="sub-heading">SAFETY</p>
              <ul>
                <li className="paragraph">Children below the age of 12 should remain within arm's reach of a responsible person (Adult) in all pools exceeding O.5m in depth.</li>
                <li className="paragraph">Supervise your children and arrange a meeting place in case of separation.</li>
                <li className="paragraph">Please be aware of all water depths that you plan to enter, and such activities present a risk of injury.</li>
                <li className="paragraph">Always swim with somebody, never swim alone.</li>
                <li className="paragraph">Apply adequate sunscreen at least 15 minutes before entering the pools.</li>
                <li className="paragraph">Evaluate each attraction and see if you can safely participate - you are the best judge of your limitations.</li>
                <li className="paragraph">
                  For your protection, please follow safety rules posted at each ride and listen to the lifeguard instructions before surfing, bodyboarding or using any attraction.
                </li>
                <li className="paragraph">Surf Abu Dhabi Management reserves the right to stop picture taking or video recording and confiscate any inappropriate material.</li>
                <li className="paragraph">Grounds are monitored by CCTV.</li>
                <li className="paragraph">Smoking is allowed in designated areas only.</li>
                <li className="paragraph">Locker rental is available for your convenience. Please do not leave valuables unattended.</li>
                <li className="paragraph">Surf Abu Dhabi accepts no responsibility for the loss or damage of any personal items.</li>
                <li className="paragraph">Management reserves the right to ask Guests who violate any policy to leave the property.</li>
                <li className="paragraph">
                  Pregnant Guests or Guests with any medical, health, or self-imposed restrictions on their physical activity, or under "doctor's care" should not use attractions.
                </li>
                <li className="paragraph">
                  Some activities are body active, participatory activities. Such activities present a risk of injury and may be restricted to certain physical ability, weight and height ranges.
                </li>
                <li className="paragraph">Some rides require a specific riding position. Guests unable to maintain such a position will not be permitted to ride.</li>
                <li className="paragraph">
                  Certain attractions contain bright lighting effects that flash or strobe. Persons with sensitivity to these conditions or a history of photosensitive epilepsy should avoid these
                  attractions.
                </li>
              </ul>
            </div>
            <div>
              <p className="sub-heading">DRESS CODE:</p>
              <ul>
                <li className="paragraph">
                  Surf Abu Dhabi is a family-oriented property. In view of preserving healthy family environment, modest dress and swimwear is expected from all guests. Revealing or offensive clothes,
                  provocative or thongs swimwear are not considered appropriate and are not allowed.
                </li>
                <li className="paragraph">
                  Appropriate swim attire is required when visiting Surf Abu Dhabi, Street clothes, Jean shorts, long flowing clothing, exposed metal that may come into contact with the equipment or
                  pools and underwear…etc, will not be permitted on the attractions.
                </li>
                <li className="paragraph">
                  All Guests in diapers MUST wear a waterproof/swim diaper to enter the water. Swim diapers are available for purchase at "Surf Station". This diaper policy is strictly enforced.
                </li>
                <li className="paragraph">Wearing sunglasses and prescription lenses is not allowed when using some of the attractions, please refer to safety guide posted at each ride.</li>
                <li className="paragraph">The Management reserves the right to determine if swimwear is appropriate.</li>
                <li className="paragraph">Always keep your slippers on while out of the water. Caution! Pavement might be very hot!</li>
              </ul>
            </div>
            <div>
              <p className="sub-heading">THINGS THAT ARE NOT PERMITTED IN SURF ABU DHABI:</p>
              <ul>
                <li className="paragraph">Outside food and beverage, or any glass items.</li>
                <li className="paragraph">Food is prohibited inside the pools.</li>
                <li className="paragraph">Inner-tubes, bodyboards, soft tops and surfboards should not be used as a substitute for internationally recognized personal floatation devices.</li>
                <li className="paragraph">Running, jumping, diving and horseplay are not allowed inside Surf Abu Dhabi premises.</li>
                <li className="paragraph">
                  Some language, gestures and suggestive art or bad surf ettiquette, as well as, showing personal affection in public, may also be considered inappropriate and in violation to UAE
                  public conduct law.
                </li>
                <li className="paragraph">Smoking in non-designated smoking areas.</li>
                <li className="paragraph">Intoxication.</li>
                <li className="paragraph">Weapons of any kind.</li>
                <li className="paragraph">Pets (except service animals in special cases that will be determined by the Management).</li>
              </ul>
            </div>
            <div>
              <p className="sub-heading">LIMITED LICENSE</p>
              <p className="paragraph">
                Subject to the terms and conditions set forth in this Agreement, Modon grants you a non-exclusive, non-transferable, limited right to access, use and display this site and the
                materials thereon. You agree not to interrupt or attempt to interrupt the operation of the site in any way.
              </p>
            </div>
            <div>
              <p className="sub-heading">COPYRIGHT</p>
              <p className="paragraph">
                Copyright of all the website content such as, but not limited to text, images, videos, audio, interactive presentations, is owned by Modon unless otherwise indicated.
              </p>
            </div>
            <div>
              <p className="sub-heading">DISCLAIMER</p>
              <p className="paragraph">
                Whilst every effort is made to ensure the accuracy of the content, Modon’s does not warrant the accuracy or completeness of the materials or the reliability of any advice, opinion,
                statement or other information displayed or distributed through the site. You acknowledge that any reliance on any such opinion, advice, statement, memorandum, or information shall be
                at your sole risk. Modon’s accepts no liability whatsoever for any such reliance by you on this information.
              </p>
              <p className="paragraph">
                Modon’s reserves the right, in its sole discretion, to correct any errors or omissions in any portion of the site. Modon’s may make any other changes to the site, the materials and the
                products, programs, services or prices (if any) described in the site at any time without notice.
              </p>
              <p className="paragraph">
                This site, the information and materials on the site, and the software made available on the site, are provided "as is" without any representation or warranty, express or implied, of
                any kind, including, but not limited to, warranties of merchantability, non-infringement, fitness for any particular purpose, or non-infringement of any content published on or
                available throughout the site; that the server that makes this site available is free of viruses or other components that may infect, harm or cause damage to your computer equipment or
                any other property when you access, browse, download from or otherwise use this site.
              </p>
              <p className="paragraph">
                Under no circumstances, including but not limited to Modon’s negligence, shall Modon be liable for any consequential, direct, incidental, indirect, punitive or special damages related
                to the use of, the inability to use, or errors or omissions in the contents and functions of this site.
              </p>
              <p className="paragraph">
                By using this website, you accept this disclaimer in full. If you disagree with any part of this disclaimer, do not use our website. All information posted on this website is provided
                for information purposes only.
              </p>
              <p className="paragraph">
                Modon makes no representations or warranties in relation to accuracy or completeness of the information and materials provided on this website for whatsoever reason.
              </p>
            </div>
            <div>
              <p className="sub-heading">METHOD OF PAYMENT, CARD TYPES ACCEPTED AND CURRENCY:</p>
              <p className="paragraph">We accept payments online using Visa and MasterCard credit/debit card in AED.</p>
              <div className="brand-images">
                <img src="https://d2rvxcax2co8em.cloudfront.net/visa.webp" alt="Visa" />
                <img src="https://d2rvxcax2co8em.cloudfront.net/mastercard.webp" alt="MasterCard" />
                <img src="https://d2rvxcax2co8em.cloudfront.net/verifiedvisa.webp" alt="Verify By Visa" />
                <img src="https://d2rvxcax2co8em.cloudfront.net/mastersecure.webp" alt="MasterCard Secure" />
              </div>
            </div>
            <div>
              <p className="sub-heading">REFUNDS & CANCELLATION:</p>

              <ul>
                <li className="paragraph">All tickets are non-transferable and non-refundable.</li>
                <li className="paragraph">No substitutions or changes will be allowed.</li>
                <li className="paragraph">This includes power outages and natural disasters.</li>
                <li className="paragraph">Re-scheduling can be done subject to availability.</li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Terms;
