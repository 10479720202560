import React from "react";
import TextTransition, { presets } from 'react-text-transition';
import { withTranslation } from "react-i18next";

const TextLoopAnimation = (props) => {
  const TEXTS = [props.t("fold2.longest"), props.t("fold2.largest"), props.t("fold2.highest")];
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <h3>
      <TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition>
    </h3>
  )};
  export default withTranslation()(TextLoopAnimation);
