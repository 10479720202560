import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { FaInstagram, FaFacebookF, FaYoutube, FaTiktok } from "react-icons/fa";
import * as bootstrap from "bootstrap/dist/js/bootstrap";
import i18n from "i18next";
import { initReactI18next, withTranslation } from "react-i18next";
import "./App.scss";
import "./App_ar.scss";
import SelectDropdownSearch from "./component/selectsearch/selectdd";
import Swal from "sweetalert2";
import axios from "axios";
import Lottie from "lottie-react";
import WaveAnimation from "./images/WaveAnimation.json";
import Thumbnail from "./images/thumbnail.webp";
import ReactPlayer from "react-player/lazy";
import { CSSTransition } from "react-transition-group";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
var cdnUrl = "https://d2rvxcax2co8em.cloudfront.net";
const youTubeUrl = "https://vimeo.com/996085551";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      translations: require("./i18n/en.json"),
    },
    ar: {
      translations: require("./i18n/ar.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: ".",
});

i18n.languages = ["en", "ar"];

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      innerWidth: window.innerWidth,
      readMoreBtn: false,
      registerModelSH: false,
      countryJsonData: [],
      countryDropDon: [],
      latestReleases_en: [
        {
          backgroundImage: cdnUrl + "/latest-release-00.webp",
          date: "08 Aug 2024",
          title: "Opening Announcement",
          description: "The highly anticipated Surf Abu Dhabi, home to the world’s most advanced man-made wave, is set to open to the public on  October 2024.",
          href: "https://www.modon.ae/media-centre/details/2024/08/12/surf-abu-dhabi-to-open-to-the-public-in-october-2024",
          file: "url",
        },
        // {
        //   backgroundImage: cdnUrl + "/latest-release-02.webp",
        //   date: "15 Aug 2024",
        //   title: "Surf Abu Dhabi Confirmed as New Venue on World Surf League 2025 Championship Tour",
        //   description: "The World Surf League (WSL) announced Surf Abu Dhabi, located on Hudayriyat Island in Abu Dhabi, as a competition venue on the 2025 Championship Tour (CT), and as the third stop on the 2024 WSL Longboard Tour.",
        //   href: "https://www.modon.ae/media-centre/details/2023/06/15/in-line-with-directives-of-mohamed-bin-zayed-modon-properties-reveals-hudayriyat-island-masterplan-3",
        //   file: "url",
        // },
      ],
      latestReleases_ar: [
        {
          backgroundImage: cdnUrl + "/latest-release-01.webp",
          date: "08  أغسطس 2024",
          title: 'إعلان الإفتتاح',
          description: 'أعلنت سيرف أبوظبي، الوجهة الأكبر والأحدث لركوب الأمواج على مستوى العالم، والتي طورتها "مُدن القابضة" بالتعاون مع شركة كيلي سلاتر ويف، عن افتتاحها الرسمي في شهر أكتوبر 2024.',
          href: "https://www.modon.ae/media-centre/details/2024/08/12/surf-abu-dhabi-to-open-to-the-public-in-october-2024",
          file: "url",
        },
        // {
        //   backgroundImage: cdnUrl + "/latest-release-02.webp",
        //   date: "أبوظبي ,  15 يونيو 2023",
        //   title: 'شراكة "مدن" مع شركة "كيلي سلايتر ويف" في مشروع "سيرف أبوظبي',
        //   description: 'أبوظبي تستعد لتصبح وجهة عالمية لركوب الأمواج بعد شراكة "مدن" مع شركة "كيلي سلايتر ويف" في مشروع "سيرف أبوظبي"',
        //   href: "https://www.modon.ae/media-centre/details/2023/06/15/in-line-with-directives-of-mohamed-bin-zayed-modon-properties-reveals-hudayriyat-island-masterplan-3",
        //   file: "url",
        // },
      ],
      additionalPages: window.location.pathname == "/terms-conditions" || window.location.pathname == "/privacy-policy" ? true : false,
      widthOfLogo: window.innerWidth < 767 ? 160 : 335,
      language: "en",
      contactUsLoading: false,
      contactUs: {
        name: "",
        email: "",
        country: null,
      },
      svgContainerDivHeight: 0,
      showYoutubeVideo: false,
      manuallyTriggerPlay: false,
      captcha: false,
    };

    this.captachRef = React.createRef();
    this.playerRef = React.createRef();
  }

  componentDidMount() {
    if (!this.state.additionalPages) {
      if (window.location.hash) {
        let getSectionId = window.location.hash.split("#")[1];
        const moveToSection = document.getElementById(getSectionId);
        moveToSection.scrollIntoView({ behavior: "smooth" });
      }

      this.waitListFormSection = React.createRef();
      this.getCountryJson();
      // Fold5 - Youtube Modal.
      var youtubeModalEle = document.getElementById("youtubeModal");
      if (youtubeModalEle) {
        var self = this;
        youtubeModalEle.addEventListener("show.bs.modal", (event) => {
          self.setState(
            {
              youTubeSrc: youTubeUrl,
            },
            () => {
              // setTimeout(() => {
              //   self.setState({
              //     showYoutubeVideo: true,
              //   });
              // }, 1000);
            }
          );
        });
        youtubeModalEle.addEventListener("hide.bs.modal", (event) => {
          self.setState(
            {
              youTubeSrc: "",
            },
            () => {
              setTimeout(() => {
                self.setState({
                  showYoutubeVideo: false,
                });
              }, 1000);
            }
          );
        });
      }
    }
  }

  componentDidUpdate = () => {
    if (!this.state.additionalPages) {
      var svgContainerDivHeight = document.getElementById("svgContainerDiv").clientHeight;
      if (svgContainerDivHeight != this.state.svgContainerDivHeight && svgContainerDivHeight != 0) {
        this.setState({
          svgContainerDivHeight,
        });
      }
    }
  };

  toggleReadMore = () => {
    new bootstrap.Collapse("#multiCollapseExample1", {
      toggle: true,
    });
    new bootstrap.Collapse("#multiCollapseExample2", {
      toggle: true,
    });
    this.setState({
      readMoreBtn: !this.state.readMoreBtn,
    });
  };

  changeLanguage = () => {
    if (this.state.captcha) {
      this.captachRef.current.reset();
    }
    this.setState(
      {
        language: this.state.language == "en" ? "ar" : "en",
        captcha: false,
        contactUs: { email: "", name: "", country: "" },
      },
      () => {
        i18n.changeLanguage(this.state.language);
        document.getElementsByTagName("html")[0].setAttribute("dir", this.state.language == "en" ? "ltr" : "rtl");
      }
    );
  };

  register_closeBtn = () => {
    this.setState({
      registerModelSH: false,
    });
  };

  regiseterBannerBtn = () => {
    this.setState({
      registerModelSH: true,
    });
  };

  getCountryJson = () => {
    fetch("../data/countries.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data, 'get code');
        this.setState(
          {
            countryJsonData: data,
          },
          () => {
            var arrayPush = [];
            this.state.countryJsonData.map((countryData) => {
              var obj = new Object();
              obj.value = countryData.name;
              obj.label = countryData.name;
              arrayPush.push(obj);
              this.setState({
                countryDropDon: arrayPush,
              });
            });
            console.log(arrayPush, "asdas");
          }
        );
      })
      .catch((ex) => {
        console.log(ex.error);
      });
  };

  updateContactUs = (name, value) => {
    this.setState({
      contactUs: { ...this.state.contactUs, [name]: value },
    });
  };

  onContactUsSubmit = () => {
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
    document.getElementsByTagName("html")[0].style.pointerEvents = "none";
    this.setState(
      {
        contactUsLoading: true,
      },
      () => {
        var contactUsData = {
          email: this.state.contactUs.email,
          first_name: this.state.contactUs.name,
          country: this.state.contactUs.country == null ? "" : this.state.contactUs.country.value,
          city: "",
          last_name: "",
        };
        axios({
          // url: "https://modon-webdev.influx.co.in/customnots/contact",
          // url: "https://uat.surfabudhabi.com/customnots/contact",
          url: "https://www.surfabudhabi.com/customnots/contact",
          method: "POST",
          data: contactUsData,
          auth: {
            username: process.env.REACT_APP_USERNAME,
            password: process.env.REACT_APP_PASSWORD,
          },
        }).then((response) =>
          this.setState(
            {
              contactUs: { email: "", name: "", country: "" },
              contactUsLoading: false,
              registerModelSH: false,
              captcha: false,
            },
            () => {
              document.getElementsByTagName("html")[0].style.overflow = null;
              document.getElementsByTagName("html")[0].style.pointerEvents = null;
              Swal.fire({
                title: this.props.t("successModal.title"),
                text: this.props.t("successModal.subHeading"),
                showConfirmButton: false,
                imageUrl: cdnUrl + "/contactUsSubmitSuccess.svg",
                imageWidth: 91,
                imageHeight: 19,
                imageAlt: "Wave Image",
                showCloseButton: true,
                closeButtonHtml: `<img src=${cdnUrl + "/closeButton.svg"}></img>`,
                customClass: {
                  container: "contactUsSuccessSwal",
                },
              });
            }
          )
        );
      }
    );
  };

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  onChangeRecaptcha = (value) => {
    if (value != null && value.length > 5) {
      this.setState({
        captcha: true,
      });
    }
    console.log("Captcha value:", value);
  };

  render() {
    var self = this;

    return (
      <>
        <Helmet>
          <meta name="title" content={this.props.t("meta.title")} />
          <meta property="og:title" content={this.props.t("meta.title")} />
          <meta name="twitter:title" content={this.props.t("meta.title")} />
          <title>{this.props.t("meta.title")}</title>
        </Helmet>
        {!this.state.additionalPages ? (
          <React.Fragment>
            <div className="row m-0">
              {/*Fold1*/}
              <div className="col-12 p-0 fold1">
                <div className="player-wrapper">
                  <ReactPlayer
                    ref={this.playerRef}
                    url={this.state.innerWidth > 767 ? cdnUrl + "/SurfVideo.mp4" : cdnUrl + "/SurfVideo_Mobile.mp4"}
                    width="100%"
                    height={"100%"}
                    playing={true}
                    loop={true}
                    volume={0}
                    muted={true}
                    playsinline={true}
                    style={{ position: "relative", zIndex: 2 }}
                  />
                  <img src={Thumbnail} style={{ position: "absolute", top: 0, width: "100%", zIndex: 1, height: "100vh", objectFit: "cover" }}></img>
                </div>
              </div>

              <div className="col-12 fold1OverlayDiv">
                <div className="row fold1ContentDiv">
                  <div className="col-12 logoDiv">
                    <img src={this.state.language == "en" ? cdnUrl + "/surf_logo.svg" : cdnUrl + "/surf_logo_arabic.svg"} className="logoImage" />
                    <button type="button" className="btn languageButton" onClick={() => this.changeLanguage()}>
                      {this.state.language == "en" ? "عربي" : "English"}
                    </button>
                  </div>
                  <div className="col-12 letsGoDiv">
                    <p className="letsGo">{this.props.t("fold1.findYourWave")}</p>
                    <p className="letsGoAnswer">{this.props.t("fold1.openingSoon")}</p>
                    {/* <p className="letsGo">{this.props.t("fold1.letsGo")}</p>
                    <p className="letsGoQuestion">{this.props.t("fold1.fold1OverlayLine1")}</p>
                    <p className="letsGoAnswer">{this.props.t("fold1.line2")}</p> */}

                    {/* <button
                      type="button"
                      className="joinWaitlistButton"
                      onClick={() => {
                        this.regiseterBannerBtn();
                      }}
                    >
                      {this.props.t("fold1.register")}
                    </button> */}
                  </div>
                  {/* <div className="col-12 letsGoDiv">
                    <p className="letsGo">Let’s Go!</p>
                    <p className="letsGoQuestion">Are you ready to get barrelled in the Middle East?</p>
                    <p className="letsGoAnswer">That’s right, the World’s largest man-made wave is coming to Abu Dhabi!</p>
                    <button type="button" className="joinWaitlistButton" onClick={() => document.getElementById("footerFormSectionH2").scrollIntoView({ behavior: "smooth" }, true)}>
                      Join Waitlist
                    </button>
                  </div> */}
                  <div className="col-12 p-0 svgContainerDiv" id="svgContainerDiv">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="310" viewBox="0 0 1920 310" fill="none">
                      <path
                        d="M0 309.605V55.1048C263.861 140.563 421.905 57.4723 596 17.1048C891.12 -51.325 907.198 110.696 1258 65.6048C1394.78 48.0262 1462.53 58.4854 1534.55 131.186C1583.91 181.049 1620.32 214.258 1684.92 234.612C1752.83 255.989 1817.81 261.735 1920 241.965V309.605H0Z"
                        fill="#fffcf8"
                      />
                    </svg>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                      <path
                        fill="#ffffff"
                        fill-opacity="1"
                        d="M0,32L26.7,80C53.3,128,107,224,160,240C213.3,256,267,192,320,154.7C373.3,117,427,107,480,117.3C533.3,128,587,160,640,144C693.3,128,747,64,800,58.7C853.3,53,907,107,960,138.7C1013.3,171,1067,181,1120,192C1173.3,203,1227,213,1280,234.7C1333.3,256,1387,288,1413,304L1440,320L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
                      ></path>
                    </svg> */}
                  </div>
                </div>
              </div>
              {/*Model*/}

              <CSSTransition key="1" transitionName="fade" transitionAppear transitionAppearTimeout={500} transitionEnter={false} transitionLeave={false}>
                <div id="biggest_wave" className="col-12 fold02">
                  <div className="lwf-banner">
                    <div className="lwf-cont">
                      <p className="waveIcono">
                        <span>
                          <img src={cdnUrl + "/wave_icon-01.svg"} className="waveIcon" />
                        </span>
                      </p>
                      {this.state.language == "en" ? <h2>{this.props.t("fold2.theWorld")}</h2> : ""}

                      {/* <TextLoopAnimation state={this.state} /> */}
                      <h3>{this.props.t("fold2.biggest")}</h3>

                      <p>{this.props.t("fold2.discoverTheUnique")}</p>
                    </div>
                  </div>
                </div>
              </CSSTransition>

              {/*Fold3*/}
              <CSSTransition key="1" transitionName="fade" transitionAppear transitionAppearTimeout={500} transitionEnter={false}>
                <div id="find_your_wave" className="col-12 fold3">
                  <section className="fyw-sec">
                    <h2>{this.props.t("fold3.findYourWave")}</h2>
                    <nav>
                      <ul className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                        <li className="fyw-col">
                          <a className="nav-link active" id="nav-surf-tab" data-bs-toggle="tab" data-bs-target="#nav-surf" type="button" role="tab" aria-controls="nav-surf" aria-selected="true">
                            <span className="fyw-sub-img">
                              <img src={this.state.language == "en" ? cdnUrl + "/wave-icon-01.webp" : cdnUrl + "/wave-icon-01-arabic.webp"} />
                            </span>
                          </a>
                        </li>
                        <li className="fyw-col">
                          <a className="nav-link" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="false">
                            <span className="fyw-sub-img">
                              <img src={this.state.language == "en" ? cdnUrl + "/wave-icon-02.webp" : cdnUrl + "/wave-icon-02-arabic.webp"} />
                            </span>
                          </a>
                        </li>
                        <li className="fyw-col">
                          <a className="nav-link" id="nav-exp-tab" data-bs-toggle="tab" data-bs-target="#nav-exp" type="button" role="tab" aria-controls="nav-exp" aria-selected="false">
                            <span className="fyw-sub-img">
                              <img src={this.state.language == "en" ? cdnUrl + "/wave-icon-03.webp" : cdnUrl + "/wave-icon-03-arabic.webp"} />
                            </span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      <div className="tab-pane fade active show" id="nav-surf" role="tabpanel" aria-labelledby="nav-home-tab">
                        <aside className="desktop-img" href="">
                          <img className="fyw-main-img" src={cdnUrl + "/surf-02.webp"} />
                        </aside>
                        <aside className="fyw-cont">
                          <img src={cdnUrl + "/wavecontentIcon02.webp"} />
                          <p>{this.props.t("fold3.experienceTheUltimate")}</p>
                        </aside>
                      </div>
                      <div className="tab-pane fade" id="nav-learn" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <aside className="desktop-img" href="">
                          <img className="fyw-main-img" src={cdnUrl + "/learn-02.webp"} />
                        </aside>
                        <aside className="fyw-cont">
                          <img src={cdnUrl + "/wavecontentIcon02.webp"} />
                          <p>{this.props.t("fold3.exploreTheWave")}</p>
                        </aside>
                      </div>
                      <div className="tab-pane fade" id="nav-exp" role="tabpanel" aria-labelledby="nav-contact-tab">
                        <aside className="desktop-img" href="">
                          <img className="fyw-main-img" src={cdnUrl + "/experience-02.webp"} />
                        </aside>
                        <aside className="fyw-cont">
                          <img src={cdnUrl + "/wavecontentIcon02.webp"} />
                          <p>{this.props.t("fold3.discoverOurSurfing")}</p>
                        </aside>
                      </div>
                    </div>
                  </section>
                </div>
              </CSSTransition>

              <CSSTransition key="1" transitionName="fade" transitionAppear transitionAppearTimeout={500} transitionEnter={false}>
                <div className="col-12 fold5">
                  <section className="waves-wrapper">
                    <section className="waves-content">
                      <section className="recerate-sec">
                        <h1 className="recreate-head">
                          {this.props.t("fold4.recreating")} <span className="d-block"> {this.props.t("fold4.perfectWaves")}</span>
                        </h1>
                      </section>
                      <section className="surf-video">
                        <section className="sub-wrap">
                          <span className="video-sec" data-bs-toggle="modal" data-bs-target="#youtubeModal">
                            <img src={cdnUrl + "/surf-video-01.webp"} alt="" />
                            <img className="surf-logo" src={cdnUrl + "/surf-logo.webp"} alt="" />

                            <img className="btn-play" src={cdnUrl + "/btn-play.webp"} alt="" />
                          </span>
                          <p>{this.props.t("fold4.surfAbuDhabiOffers")}</p>
                        </section>
                      </section>
                    </section>
                  </section>
                </div>
              </CSSTransition>

              <div className="col-12 fold9">
                <section className="build-modon">
                  <aside className="logo-sec">
                    <h5>{this.props.t("fold5.builtBy")}</h5>
                    <img src={cdnUrl + "/modon.svg"} />
                    <span className="modon-border desk-show"></span>
                  </aside>
                  <div className="cont-sec">
                    <aside className="cont-01">
                      <p>{this.props.t("fold5.collaboration")}</p>
                      <img src={cdnUrl + "/skelly.svg"} className="skelly-ic" />
                    </aside>
                    <span className="modon-border mob-show"></span>
                    <aside className="cont-02">
                      <p>{this.props.t("fold5.abudhabiProperties")}</p>
                      <a href="https://www.kswaveco.com/" target="_blank">
                        {this.props.t("fold5.learnMore")} <img src={cdnUrl + "/learn-arrow-icon-01.svg"} alt="" />
                      </a>
                    </aside>
                  </div>
                </section>
              </div>

              <div id="latest_releases" className="col-12 fold8">
                <section class="lat-rel-sec">
                  <h2>{this.props.t("fold6.latest")}</h2>
                  <div id="Latestcarousel" class={`carousel slide w-100`} data-bs-ride="false" data-bs-touch="true" data-bs-interval="false">
                    <div class={`carousel-indicators ${this.state[this.state.language == "en" ? "latestReleases_en" : "latestReleases_ar"].length == 1 ? "d-none" : ""}`}>
                      <button type="button" data-bs-target="#Latestcarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                      <button type="button" data-bs-target="#Latestcarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    </div>
                    <div class="carousel-inner" role="listbox">
                      {this.state[this.state.language == "en" ? "latestReleases_en" : "latestReleases_ar"].map((eachRelease, eachReleaseIndex) => {
                        return (
                          <div class={`carousel-item ${eachReleaseIndex == 0 ? "active" : ""} `}>
                            <div class="carousel-img">
                              <img class="slider-img" src={eachRelease.backgroundImage} alt="First slide" />
                            </div>
                            <div class="carousel-caption">
                              <p>{eachRelease.date}</p>
                              <h3>{eachRelease.title}</h3>
                              <p>{eachRelease.description}</p>
                              <a href={eachRelease.file == "url" ? eachRelease.href : require("./" + eachRelease.href)} aria-label="" target={"_blank"} className="desktopReadMore">
                                {this.props.t("fold6.readMore")}
                                <img src={cdnUrl + "/learn-arrow-icon-01.svg"} alt="" />
                              </a>
                            </div>
                            <a href={eachRelease.file == "url" ? eachRelease.href : require("./" + eachRelease.href)} aria-label="" target={"_blank"} className="mobileReadMore">
                              {this.props.t("fold6.readMore")}
                              <img src={cdnUrl + "/learn-arrow-icon-01.svg"} alt="" />
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <button
                    class={`carousel-control-prev ${this.state[this.state.language == "en" ? "latestReleases_en" : "latestReleases_ar"].length == 1 ? "d-none" : ""}`}
                    data-bs-target="#Latestcarousel"
                    type="button"
                    data-bs-slide="prev"
                  ></button>
                  <button
                    class={`carousel-control-next  ${this.state[this.state.language == "en" ? "latestReleases_en" : "latestReleases_ar"].length == 1 ? "d-none" : ""}`}
                    data-bs-target="#Latestcarousel"
                    type="button"
                    data-bs-slide="next"
                  ></button>
                </section>
              </div>

              <CSSTransition key="1" transitionName="fade" transitionAppear transitionAppearTimeout={500} transitionEnter={false}>
                <div className="col-12 fold6">
                  <section class="footer-sec">
                    <div class="foot-form-sec container">
                      <aside id="register_today" className="foot-form-head">
                        <h2 id="footerFormSectionH2">{this.props.t("fold7.register")}</h2>
                        <p>{this.props.t("fold7.updates")}</p>
                      </aside>
                      <div class="form-inline">
                        <div class="form-group">
                          <input
                            type="name"
                            class="form-control"
                            id="name"
                            placeholder={this.props.t("fold7.name")}
                            name="name"
                            onChange={(e) => this.updateContactUs("name", e.target.value)}
                            value={this.state.contactUs.name}
                            maxlength="50"
                          />
                          <span></span>
                        </div>
                        <div class="form-group form-email">
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            placeholder={this.props.t("fold7.email")}
                            name="email"
                            onChange={(e) => this.updateContactUs("email", e.target.value)}
                            value={this.state.contactUs.email}
                          />
                          <span></span>
                        </div>

                        <SelectDropdownSearch state={this.state} updateContactUs={this.updateContactUs} />
                        <ReCAPTCHA ref={this.captachRef} sitekey="6LdWkwEpAAAAAE8Cfyx4nSwQGlEDXRpmQOFZKkoQ" onChange={this.onChangeRecaptcha} style={{ marginTop: 40 }} />

                        <button
                          class={`btn btn-default ${this.validateEmail(this.state.contactUs.email) && this.state.captcha ? "" : "disabled"} ${
                            this.state.contactUsLoading ? "buttonLoading disabled" : ""
                          }`}
                          onClick={() => this.onContactUsSubmit()}
                        >
                          {this.state.contactUsLoading ? <Lottie animationData={WaveAnimation} loop={true} style={{ height: 80, marginTop: -10 }}></Lottie> : this.props.t("fold7.signUp")}
                        </button>
                      </div>
                    </div>
                    <div class="foot-bt-sec container">
                      <div className="address">
                        <p>
                          Surf Abu Dhabi LLC - Al Hudayriyat Island, <br></br>Abu Dhabi, UAE<br></br>
                          {/* T +971 2 204 7777  |  F +971 2 204 7778 */}
                          For corporate and group inquiries,<br></br>
                          please email{" "}
                          <a href="mailto:info@surfabudhabi.com" aria-label="send an email to info@surfabudhabi.com">
                            info@surfabudhabi.com.
                          </a>
                        </p>
                      </div>
                      <ul class="foot-social">
                        <li>
                          <a href="https://www.instagram.com/surfabudhabi/" aria-label="instagram" target="_blank">
                            <FaInstagram />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.facebook.com/surfabudhabi" aria-label="facdbook" target="_blank">
                            <FaFacebookF />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/@SurfAbuDhabi" aria-label="YouTube" target="_blank">
                            <FaYoutube />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.tiktok.com/@surf.abudhabi" aria-label="Tiktok" target="_blank">
                            <FaTiktok />
                          </a>
                        </li>
                      </ul>
                      <p class="p3">
                        {this.state.language == "en" ? (
                          <>
                            © {this.props.t("footer.copyRightModon")} {new Date().getFullYear()}. {this.props.t("footer.rightReservedText")}.
                          </>
                        ) : (
                          <>
                            {this.props.t("footer.rightReservedText")} {new Date().getFullYear()} ©
                          </>
                        )}
                      </p>
                      <p className="p4">
                        <a href="/terms-conditions">Terms & conditions</a>
                        <a href="/privacy-policy">Privacy policy</a>
                      </p>
                    </div>
                  </section>
                </div>
              </CSSTransition>
            </div>
            {/* Fold5 - Youtube Modal */}
            <section className="modal fade" id="youtubeModal">
              <section className="modal-dialog modal-lg modal-xl modal-dialog-centered">
                <section className="modal-content">
                  <a className="btnClose" href="javascript:;" data-bs-dismiss="modal">
                    <img className="fyw-main-img img-fluid" src={cdnUrl + "/modal-close.webp"} />
                  </a>
                  <section className="modal-body">
                    <section className="iframeWrapper ratio ratio-16x9">
                      {/* <iframe
                        width="100%"
                        height="400"
                        src={`${this.state.youTubeSrc != "" ? this.state.youTubeSrc : ""}`}
                        title="Recreating The Perfect Waves"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe> */}
                      <ReactPlayer
                        controls={true}
                        url={`${this.state.youTubeSrc != "" ? this.state.youTubeSrc : ""}`}
                        playing={true}
                        width="100%"
                        height="400"
                        onPlay={() => {
                          this.setState({
                            showYoutubeVideo: true,
                          });
                        }}
                      />
                      <img src={cdnUrl + "/surf-video-01.webp"} alt="" style={{ objectFit: "cover", display: this.state.showYoutubeVideo ? "none" : "" }} />
                    </section>
                  </section>
                </section>
              </section>
            </section>
          </React.Fragment>
        ) : null}
        <Router>
          <Routes>
            <Route path="/terms-conditions" element={<Terms />} />
            <Route path="/privacy-policy" element={<Policy />} />
          </Routes>
        </Router>
      </>
    );
  }
}
export default withTranslation()(App);
