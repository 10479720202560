import React, { Component, useState } from "react";
import Modal from "react-bootstrap/Modal";

import SelectDropdownSearch from "../selectsearch/selectdd";
import { withTranslation } from "react-i18next";

import Lottie from "lottie-react";
import WaveAnimation from "../../images/WaveAnimation.json";

const RegisterModel = (props) => {
  return (
    <Modal {...props} className="reg-model-popup" aria-labelledby="contained-modal-title-vcenter" centered>
      <section className="reg-model">
        <Modal.Body>
          <aside className="modal-form">
            <a onClick={props.register_closeBtn} className="modal-close" href="javascript:;" role="button">
              <img className="fyw-main-img" src={props.cdnUrl + "/closeButton.svg"} />
            </a>
            <div className="pre-modal">
              <p>{props.t("fold7.updates")}</p>
              <div class="form-inline">
                <div class="form-group">
                  <input type="name" class="form-control" id="name" placeholder={props.t("fold7.name")} name="name" onChange={(e) => props.updateContactUs("name", e.target.value)} />
                  <span></span>
                </div>
                <div class="form-group form-email">
                  <input type="email" class="form-control" id="email" placeholder={props.t("fold7.email")} name="email" onChange={(e) => props.updateContactUs("email", e.target.value)} />
                  <span></span>
                </div>

                <SelectDropdownSearch state={props.state} updateContactUs={props.updateContactUs} />
                <button
                  type="submit"
                  class={`btn btn-default ${props.validateEmail(props.state.contactUs.email) ? "" : "disabled"} ${props.state.contactUsLoading ? "buttonLoading disabled" : ""}`}
                  onClick={() => props.onContactUsSubmit()}
                >
                  {props.state.contactUsLoading ? <Lottie animationData={WaveAnimation} loop={true} style={{ height: 70, marginTop: -22 }}></Lottie> : props.t("fold7.register")}
                </button>
              </div>
            </div>
          </aside>
        </Modal.Body>
      </section>
    </Modal>
  );
};
export default withTranslation()(RegisterModel);
