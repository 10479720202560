import React, { Component } from "react";
var cdnUrl = "https://d2rvxcax2co8em.cloudfront.net";

class Policy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="surf-static-page privacy-policy">
          <div className="header-wrap">
            <a className="logo-sec" href="/" alt="">
              {" "}
              <img src={cdnUrl + "/surf_logo.svg"} className="logoImage" />
            </a>
          </div>
          <div className="static-page-container">
            <p className="page-title">PRIVACY POLICY</p>
            <div>
              <p className="paragraph">
                This website is developed and owned by Modon Properties PJSC and its subsidiaries (“Modon”, “We”, “Our” or “Us”). Modon currently manages and operates Surf Abu Dhabi faciilities in
                Hudayriyat Island, Abu Dhabi. Modon has created this privacy policy (the "Site Policy") to demonstrate our commitment to the privacy of our website visitors and the right of privacy
                throughout the Internet. Privacy on this Modon website is of great importance to us. Because we gather important information from visitors to our Site, we have established this policy
                to communicate our information gathering and dissemination practices.
              </p>
              <p className="paragraph">
                Modon will treat your personal information in accordance with the privacy statement in place at the time of collection of such information, or as you otherwise consent. By using this
                Site, you are agreeing to the terms of the Site Policy. We reserve the right to change the Site Policy at any time and at Our discretion. It is the responsibility of the website
                visitor to visit this page to learn about any updates/changes to the Site Policy.
              </p>
              <p className="paragraph">All credit/debit cards details and personally identifiable information will not be stored, sold, shared, rented or leased to any third parties.</p>
              <p className="paragraph">
                The website’s policies and terms and conditions may be changed or updated occasionally to meet the requirements and standards. Therefore, the customers are encouraged to frequently
                visit these sections in order to be updated about the changes on the website. Modifications will be effective on the day they are posted.
              </p>
            </div>
            <p className="sub-title">COLLECTED INFORMATION</p>
            <div>
              <p className="sub-heading">1. ANONYMOUS INFORMATION</p>
              <p className="paragraph">
                You can visit the website to read information about Our company, its products and services without telling us who you are and without revealing any personal information. To improve the
                usefulness of Our site, Modon measures and analyzes non-identifying, aggregate usage and volume statistical information from website visitors in order to administer Our website, and to
                constantly improve the quality of our service and site performance.
              </p>
              <p className="paragraph">
                Information gathered may include, but is not limited to, the following: the activity of the website visitor's browser when the visitor is viewing the website; the site path of
                visitors; the time visitors come to the website; and the amount of time visitors spend on the website. Please note that this is not personal information, only general summaries of our
                visitors' usage of the website.
              </p>
            </div>
            <div>
              <p className="sub-heading">2. PERSONALLY IDENTIFIABLE INFORMATION</p>
              <p className="paragraph">
                You may wish to request information about products and services or request to be contacted. To facilitate the distribution of this content, you are asked to provide information, such
                as, but not limited to, first name, last name, company, title, phone number, post box number, e-mail address, site name, URL, and address. Based on your request, Modon may also ask for
                additional information, such as the number of members in your household, and how you heard about us. This information is provided by you on a voluntary basis only and is not required
                by Us for you to use and enjoy Our website. We might also ask you to provide personal information by submitting online forms or by using the Contact Us page. The personal information
                may also include the information You provided during sales event; marketing campaign and your responses to surveys which We might have asked You to fill in for our marketing or
                research purposes.
              </p>
              <p className="paragraph">
                Personal information may be provided to a third party if: You requested or approved disclosing your personal information; We are required or allowed by law to make such disclosure;
                such disclosure is part of marketing agreements We have entered into; if made to reputable reporting agencies, subpoena, or during the investigation of a fraudulent activity and if
                such disclosure is made to Modon subsidiaries to market or enhance Our products and services.
              </p>
            </div>
            <div>
              <p className="sub-heading">3. INFORMATION USE AND DISCLOSURE</p>
              <p className="paragraph">
                Modon uses the information that we collect on the website to contact you when requested in order to assess and further discuss your interest in Our company, Our services, and to send
                information regarding Our company or partners, such as marketing promotions and events. This information may also be used to understand your needs and improve the services We provide
                you and to speed up your preferred searches on our website. The information is collected and stored in a manner that is in compliance with the federal laws and regulations by employing
                recommended security practices and that is appropriate for the nature of the data that We collect, and the need to fulfill your request. This information is not provided to third
                parties for their own use, unless specifically authorized by you.
              </p>
            </div>
            <p className="sub-title">COOKIES AND CLEAR GIFS</p>
            <div>
              <p className="sub-heading">1. WHAT ARE COOKIES?</p>
              <p className="paragraph">
                Cookies are files that web servers placed on an internet user's computer that are designed to store basic information (such as visitor preferences). The use of cookies on this website
                allows visitors to enjoy more seamless visits and more accurately measures visitor behavior on the website. There are two types of cookies: session and persistent. Session cookies
                exist only during an internet user’s online session and they disappear from the user’s computer when he/she closes the browser software. Persistent cookies remain on the user’s
                computer after the browser has been closed. The cookies used on the website do not identify you personally. They merely recognize your browser, unless you choose to identify yourself
                voluntarily. You may choose to identify yourself for any one by: asking the browser to remember your username and password; responding to a promotional offer; or personalizing a web
                page or requesting more information on a product or service. Otherwise you remain anonymous.
              </p>
            </div>
            <div>
              <p className="sub-heading">2. HOW WE USE COOKIES</p>
              <p className="paragraph">
                When you interact with the website, we strive to make that experience easy and meaningful. When you come to our website, our web server uses cookies to improve your interaction with
                the website by, for example, remembering your preferences. More specifically, Modon uses cookies to measure your behavior on this website and to save personal settings on specific
                pages. For our customers who login to the Modon portal, cookies can also be used to save the username and password so that Modon can process the saved login information and quickly log
                that user in. Cookies also help us make sure you are who you say you are after you've logged in. Modon uses cookies (that only Modon can read and use) to identify the fact that you are
                a Modon customer or a prior website visitor (whatever the case may be). Users who disable their web browsers' ability to accept cookies will still be able to browse the website;
                however, they may lose some of the functionality provided using cookies.
              </p>
              <p className="paragraph">
                Cookies also allow us to supply you with advertising that is more relevant to you when you are visiting our websites or other third party websites that promote our brand/products
                (retargeting cookies).
              </p>
            </div>
            <div>
              <p className="sub-heading">3. CONTROLLING OUR USE OF COOKIES</p>
              <p className="paragraph">
                You can accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to
                decline cookies, you may not be able to sign in or use other interactive features of Modon’ websites and services that depend on cookies.
              </p>
            </div>
            <div>
              <p className="sub-heading">4. WHAT ARE CLEAR GIFS?</p>
              <p className="paragraph">
                Clear gifs (also known as web beacons) are used in combination with cookies to help Site operators understand how visitors interact with their websites. A clear gif is typically a
                transparent graphic image (usually 1 pixel x 1 pixel) that is placed on a site. The use of a clear gif allows the website to measure the actions of the visitor opening the page that
                contains the clear gif.
              </p>
            </div>
            <div>
              <p className="sub-heading">5. HOW WE USE CLEAR GIFS</p>
              <p className="paragraph">
                We utilize clear gifs to measure visitor behavior, to help us improve visitor experience, and to manage site content. We may use clear gifs on this website, in our e-mails, in our
                advertisements on other websites, or in our advertisements in others' e-mails.
              </p>
            </div>
            <p className="sub-title">THIRD PARTY SITES</p>
            <div>
              <p className="paragraph">
                The website may contains links to other non-Modon websites. Modon is not responsible for the privacy practices or the content of these other websites. Customers and/or visitors should
                check the privacy policy statements of these other websites to understand their policies. Customers and visitors who access a linked website may be disclosing their private
                information. It is the responsibility of the user to keep such information private and confidential.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Policy;
